@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

body {
  margin: 0;
  font-family: 'Noto Sans JP', 'Roboto', 'Segoe UI', 'Oxygen', 'Bangers'
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Indie Flower',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
